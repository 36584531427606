@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@tailwind components;
@tailwind utilities;

#root{
    width: 100vw;
    height: 100vh;
}
html {
    font-size: 16px;
}
html *{
    font-family: 'Quicksand', serif, SansSerif, Arial, Tahoma !important;
}
body{
    overflow-x: hidden;
}
